import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const CustomPublish = () => {


    return (
<>


<Layout>

<div id="contentarticle" className="pb4 custom4">

<div className="w-100 center bg-black-800 ">
<div className="w-100 gray-50 pv3 animate-03 fadeInSubtle">
<p className="f3 fw6 ls-sm ">A Case Study in Modern Publishing <span className="fw4"><br/>– efficient, democratic, and contemporary</span>  </p>
</div>
</div>

<div className="w-100 center bg-black-100">
<div className="w-100 pv3">

<p>Traditional publishing of books, journals, magazines, and newspapers are costly exercises.</p>

<p>In spite of this the 'print on paper' sector still experiences high growth – <a className="blue-800" href="https://publishingperspectives.com/2021/04/us-first-quarter-2021-print-book-sales-grew-29-percent-covid19">
<b>US First-Quarter 2021 Print Book Sales Grew 29 Percent</b> </a>
</p>

<p>So what has changed? Plenty. The traditional commercial printing industry that started in 1454 with Gutenberg's press 
is now almost entirely digital.
Modern print technology has changed the game. Today one can manufacture on demand, low volume, high quality printed copies. 
However, the digital publishing sector has grown exponentially over the past 25 years and remains
 the primary method of modern publishing – PDF's, ePubs and websites. </p>

<p>These new digital methods have democratised the creation and distribution of content by cutting through cultural, 
political and economic barriers increasing readership by billions.</p>

<div className="w-100 black-800 pv3">
<p className="f3 fw6 ls-sm ">Paper and Pixels... the best of both worlds  </p>
</div>



</div>
</div>

<div className="w-100 mw-900 center pv3">
<StaticImage src="../content/assets/custom-publishing.jpg"   alt="..." />
</div>

<p className="f6 i fw3">
<span className="fw6">Above: (a)</span> Publication designed and ideated with Adobe Indesign/Photoshop/Illustrator.<br/>
<span className="fw6">(b)</span> Final publication ready for PDF production and Print.<br/>
<span className="fw6">(c)</span> Normal print distribution. A PDF of the original publication is distributed through websites/email/social media.
    HTML based website is a clone of the original document with adaptations that respect modern responsive web technologies and distributed through websites/email/social media campaigns.
</p>



<div className="w-100 black-800 pv3">
<p className="f3 fw6 ls-sm ">PDF's are great but... </p>
</div>

<p className="pb1" >For laptops and large desktop screens PDF versions of publications are more than adequate and in some 
instances serve communication needs better than web based html publications and epubs. However for smaller mobile screens such as tablets and cell phones,
 PDF's are not suitable (unless the PDF is designed exactly for the device size). 
The continious back and forth pinching of the screen to increase the viewing size is cumbersome and 
inefficient for users. The result – a neglected publication.<br/><br/>
<span className="fw6">The HTML website</span> version is cross platform responsive and can be viewed on any 
digital device regardless of the screen size.  </p>

<p>Below are links to three publications that employ the most up-to-date publishing technology - a hybrid of print, PDF and HTML. 
In all instances, the publications have a low volume high quality hard-copy print run. 
These are primarily distributed to targeted audiences. This minimises wastage, increases efficiency and is good for the planet. 
All publications are also available as PDF's as free downloads and below are the links to the device friendly HTML website versions of the same publications. </p>


<p className="pb1 pt3">
<a href="https://ukzn-utlo-report-2019.netlify.app/" className="link ls-xsm br-1 bg-red-900 gray-50  ph2 pv05 f6 pointer tc center dib mw-300" rel="noopener noreferrer nofollow" 
   target="_blank">
        Click to visit the UKZN Teaching and Learning 2019 Report
</a>
</p>

<p className="pb1">
<a href="https://www.sasugar.co.za/" className="link ls-xsm br-1 bg-green-900 gray-50  ph2 pv05 f6 pointer tc center dib mw-300" rel="noopener noreferrer nofollow" 
   target="_blank">
        Click to visit the SA Sugar Journal Website   
</a>
</p>

<p className="pb1">
<a href="https://www.sasugarindustrydirectory.co.za/" className="link ls-xsm br-1 bg-orange-900 gray-50  ph2 pv05 f6 pointer tc center dib mw-300" rel="noopener noreferrer nofollow" 
   target="_blank">
        Click to visit the SA Sugar Industry Directory   
</a>
</p>



</div>


</Layout>
</>
)
}


export default CustomPublish